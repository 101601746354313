import * as React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { LoginEntity, createEmptyLogin } from "../model/login";

interface PropsForm {
  onLogin: (login: LoginEntity) => void;
}

// https://material-ui.com/styles/api/#makestyles-styles-options-hook
const useFormStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })
);

export const LoginComponent: React.FC<PropsForm> = (props) => {
  const { onLogin } = props;
  const [loginInfo, setLoginInfo] = React.useState<LoginEntity>(
    createEmptyLogin()
  );
  const classes = useFormStyles({
  });
  const onTexFieldChange = (fieldId: string) => (e: { target: { value: any; }; }) => {
    setLoginInfo({
      ...loginInfo,
      [fieldId]: e.target.value,
    });
  };
 const handleLogin =(e: { preventDefault: () => void; keyCode: number; })=>{
         e.preventDefault()
         onLogin(loginInfo)
     };

  // @ts-ignore
    return <form onSubmit={handleLogin}>
        <div className={classes.formContainer}>
            <TextField
                label="Username"
                margin="normal"
                value={loginInfo.username}
                onChange={onTexFieldChange("username")}
            />
            <TextField
                label="Password"
                type="password"
                margin="normal"
                value={loginInfo.password}
                onChange={onTexFieldChange("password")}
            />
            <button type="submit" style={{width:"",marginTop:"1em",color:"white",height:"40px",borderRadius:"5px", border:"1px solid" +
                    " #cc3333", backgroundColor:"#cc3333", fontSize:"1em"}}

            >
                Login
            </button>
        </div>
    </form>
}