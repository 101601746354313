import * as React from "react";
import { Switch, Route, Router,HashRouter, BrowserRouter,Redirect} from "react-router-dom";
import { LoginPage } from "./pages/login.container";
import AppConfig from "../app";

export const App = () => {
    /*if(!localStorage.getItem("sessionId")){
        return <div>Invalid session id <BrowserRouter><Redirect exact to="/" /></BrowserRouter></div>
    }else*/
  return (

    <>
        <HashRouter>
            <div>
                <Switch>
                    <Route path='/login' component={ LoginPage } />
                    <Route path='/' render={() => {
                        const transid = localStorage.getItem("sessionId");
                        //alert(transid + " href is  "+window.location.pathname)

                        return !transid ? (
                            <Redirect to={{pathname: '/login', state: {from: window.location.pathname}}} />
                        ) : (
                            <AppConfig/>
                        );
                    }}
                    />
                </Switch>
            </div>
        </HashRouter>
        {/*<BrowserRouter>
            <Switch>
                <Route exact path="/login" component={LoginPage} />

                <Route render={() => {
                    const transid = localStorage.getItem("sessionId");
                    alert(transid + " href is  "+window.location.pathname)

                    return !transid ? (
                        <Redirect to={{pathname: '/login', state: {from: window.location.pathname}}} />
                    ) : (
                        <AppConfig/>
                    );
                }}
                />
            </Switch>
        </BrowserRouter>*/}
      {/*<BrowserRouter>
        <Switch>
          <Route render={() => {
              const transid = localStorage.getItem("sessionId");
              return !transid ? (
                  <Redirect from="/" to="/login" />
              ) : (
                  <Redirect to="/config" />
              );
          }}
                  />
            <Route path="/login" component={LoginPage} />
            <Route path="/config" component={AppConfig} />
        </Switch>
      </BrowserRouter>*/}
    </>
  );
};
