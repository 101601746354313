import * as React from "react";
import { useHistory } from "react-router-dom";
import { LoginEntity } from "../model/login";
import { isValidLogin } from "../api/login";
import { LoginComponent } from "./login.component";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import createStyles from "@material-ui/styles/createStyles";
import makeStyles from "@material-ui/styles/makeStyles";
import { NotificationComponent } from "../common";
import {withAppContext} from "../../withContext/withContext.comp";
import {IAppContext} from "../../app.context";
import logo from "../../../assets/fonts/img/exium-new-logo.jpg"

// https://material-ui.com/styles/api/#makestyles-styles-options-hook
const useFormStyles = makeStyles((theme) =>
  createStyles({
    card: {
      maxWidth: 400,
      margin: "0 auto",
      marginTop:"10em"
    }
  })
);

interface IProps {
  context: IAppContext
}

  const LoginContainer = ({ context }: IProps) => {
  const {httpService} = context;

  const history = useHistory();
  const [isShowAlert, setShowAlert] = React.useState(false);
  const [alertText,setAlertText] = React.useState("");
  const classes = useFormStyles();

  const loginSucceeded = (isValid: boolean) => {
    if (isValid) {
      history.push("/");
    } else {
      setAlertText("Invalid login or password, please type again")
      setShowAlert(true);
    }
  };

  const handleLogin = (login: LoginEntity) => {
    isValidLogin(login).then(loginSucceeded);
  };

  return (
    <>
      <Card className={classes.card}>
        <div><img src={logo} height="75px" width="50px" style={{margin: "20px 175px"}}/></div>
        <CardContent>
          <LoginComponent onLogin={handleLogin}  />
          <NotificationComponent
            show={isShowAlert}
            message={alertText}
            onClose={() => setShowAlert(false)}
          />
        </CardContent>
      </Card>
    </>
  );
};
export const LoginPage = withAppContext(LoginContainer);

